
import service from '../../until/api'

// 车辆注册管理---分页查询
export function _FindAllPage(data) {
    return service({
      url: '/vehicle-auth/carRegister/findAllPage',
      method: 'GET',
      params: data 
    })
  }


  //车辆注册管理----删除车辆
  export function _DeleteCar(data) {
    return service({
      url: '/vehicle-auth/carRegister/deleteCar',
      method: 'GET',
      params: data 
    })
  }
  

  
// 添加车辆时校验车编号

export function _CheckCarNumber(data) {
    return service({
      url: '/vehicle-auth/carRegister/checkCarNumber',
      method: 'GET',
      params: data 
    })
  }

  //添加车辆时校验车OBU信息
  export function _CheckCarObuNo(data) {
    return service({
      url: '/vehicle-auth/carRegister/checkCarObuNo',
      method: 'GET',
      params: data 
    })
  }

  //车辆注册管理---添加注册车辆
  export function _AddCarRegister(data) {
    console.log('data', data)
    return service({
      url: '/vehicle-auth/carRegister/addCarRegister',
      method: 'POST',
      data
    })
  }


  //车辆注册管理---编辑车辆
  export function _UpdateCarRegister(data) {
    console.log('data', data)
    return service({
      url: '/vehicle-auth/carRegister/updateCarRegister',
      method: 'POST',
      data
    })
  }

  //编辑车辆时校验车编号

  export function _CheckUpdateCarNumber(data) {
    return service({
      url: '/vehicle-auth/carRegister/checkUpdateCarNumber',
      method: 'GET',
      params: data 
    })
  }
  
  //修改车辆时校验车OBU信息
  export function _CheckUpdateCarObuNo(data) {
    return service({
      url: '/vehicle-auth/carRegister/checkUpdateCarObuNo',
      method: 'GET',
      params: data 
    })
  }


  //修改车辆所属企业,分配企业及回收

    export function _UpdateCarCompany(data) {
    return service({
      url: '/vehicle-auth/carRegister/updateCarCompany',
      method: 'GET',
      params: data 
    })
  }

  // 中心云车辆管理---回收车辆
  export function _RecoveryCarCompany(data) {
    return service({
      url: '/vehicle-auth/carRegister/recoveryCarCompany',
      method: 'GET',
      params: data 
    })
  }




  //车辆注册管理->导出车辆
  export function _CarExport(data) {
    return service({
      url: '/vehicle-auth/carRegister/carExport',
      method: 'GET',
      params: data,
      responseType: 'blob' 
    })
  }
    //车辆注册管理->导出车辆
    export function _CarImp(data) {
      return service({
        url: '/vehicle-auth/carRegister/carExportTemplate',
        method: 'GET',
        params: data,
        responseType: 'blob' 
      })
    }

  //车辆注册管理导入车辆
  export function _ExcelUploadCar(data) {
    console.log('data', data)
    return service({
      url: '/file-nacos/excelUpload/excelUploadCar',
      method: 'POST',
      data,
      headers: {"content-type": "multipart/form-data" },
    })
  }

  




  
  
  
