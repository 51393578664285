<template>
  <div>
    <div class="headSearch">
      <div>
        <button class="centerBtn" @click="addCarDia">添加车辆</button>
        <button class="centerBtn ml20" @click="ImportVehicle = true">
          导入车辆
        </button>
        <el-button class="centerBtn ml20" :loading="exportLoad" @click="exportC"
          >导出车辆</el-button
        >
      </div>
      <div class="mt20">
        <el-form :inline="true">
          <el-form-item>
            <el-input
              clearable
              v-model="searchObj.carNumber"
              placeholder="请输入车辆编号"
              class="centerInput"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-input
              clearable
              v-model="searchObj.carVin"
              placeholder="请输入VIN"
              class="centerInput"
            ></el-input>
          </el-form-item>
					<el-form-item>
						<el-cascader
							clearable
							v-model="selectCarType"
							class="centerSelInput centerInput"
							:options="carTypeOption"
							:props="carTypeOptionParams"
							@change="handleCarTypeOptionChange"
							:show-all-levels="false"
							placeholder="请选择车辆类型"
						></el-cascader>
					</el-form-item>
          <el-form-item>
            <el-select
              @change="getTableData"
              clearable
              :props="{ value: 'id', label: 'companyName' }"
              v-model="searchObj.companyId"
              placeholder="所属企业"
              class="centerSelInput"
            >
              <el-option
                v-show="item.companyName"
                v-for="item in enterpriseList"
                :key="item.id"
                :label="item.companyName"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <button type="button" class="centerCzbtn" @click="search">
              搜索
            </button>
          </el-form-item>
          <el-form-item>
            <button type="button" class="centerBtn resetbtn" @click="reset">
              重置
            </button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <Table
      :data1="tableData"
      :columns1="columns"
      :total="total"
      @getAjax="getTableData"
      :loading="loading"
      :page="searchObj.page"
      :size="searchObj.size"
    />

    <!-- 添加车辆弹框 -->
    <el-drawer
      :title="isAdd ? '添加车辆' : '修改车辆'"
      @close="drawClose"
      :visible.sync="drawer"
      direction="rtl"
      custom-class="demo-drawer"
      ref="drawer"
      destroy-on-close
      :wrapperClosable="isAdd"
    >
      <div class="demo-drawer__content">
        <el-form :model="form" :rules="rules" ref="form" show-message>
          <el-form-item
            label="车辆编号"
            :label-width="formLabelWidth"
            prop="carNumber"
          >
            <el-input
              v-model="form.carNumber"
              autocomplete="off"
              class="centerCzInput"
              placeholder="请输入"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="车辆VIN"
            :label-width="formLabelWidth"
            prop="carVin"
          >
            <el-input
              v-model="form.carVin"
              autocomplete="off"
              class="centerCzInput"
              placeholder="请输入"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="车辆牌证"
            :label-width="formLabelWidth"
            prop="carLicense"
          >
            <el-input
              v-model="form.carLicense"
              autocomplete="off"
              class="centerCzInput"
              placeholder="请输入"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="OBU编号"
            :label-width="formLabelWidth"
            prop="obuNo"
          >
            <el-input
              v-model="form.obuNo"
              autocomplete="off"
              class="centerCzInput"
              placeholder="请输入"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="车辆子类型"
            :label-width="formLabelWidth"
            prop="carTypeId"
          >
            <el-cascader
              v-model="form.carTypeId"
              :options="carSubType"
              class="centerCzSelInput"
              :show-all-levels="false"
              :props="{
                checkStrictly: true,
                value: 'id',
                label: 'typeName',
                emitPath: false,
              }"
              clearable
            >
            </el-cascader>
          </el-form-item>

          <el-form-item
            label="运营类别"
            :label-width="formLabelWidth"
            prop="operationTypeId"
          >
            <el-select
              @change="changeOperType"
              v-model="form.operationTypeId"
              placeholder="请选择"
              class="centerCzSelInput"
              :props="{ value: 'id', label: 'typeName' }"
            >
              <el-option
                v-for="item in operType"
                :key="item.id"
                :label="item.typeName"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="车辆座位数"
            :label-width="formLabelWidth"
            prop="seatNumber"
            v-if="leaveShow"
          >
            <el-input
              v-model="form.seatNumber"
              autocomplete="off"
              class="centerCzInput"
              placeholder="请输入"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="可坐人数"
            :label-width="formLabelWidth"
            prop="passengerNumber"
            v-if="leaveShow"
          >
            <el-input
              v-model="form.passengerNumber"
              autocomplete="off"
              class="centerCzInput"
              placeholder="请输入"
            >
            </el-input>
          </el-form-item>
        </el-form>
      </div>
      <div class="demo-drawer__footer">
        <el-button
          class="centerCzbtn mr20"
          type="button"
          @click="operation"
          :loading="AddLoading"
        >
          {{ isAdd ? "确认添加" : "确认修改" }}
        </el-button>
        <button class="centerBtn resetbtn" @click="drawer = false">取消</button>
      </div>
    </el-drawer>

    <!-- 导入车辆 -->
    <!-- ImportVehicle -->
    <el-drawer title="导入车辆" :visible.sync="ImportVehicle" direction="rtl">
      <div class="demo-drawer__content">
        <div class="importDv">
          <el-upload
            class="upload-demo"
            drag
            action="#"
            multiple
            :auto-upload="false"
            :on-change="handleUpXls"
            :file-list="fileList"
            :limit="1"
          >
            <!-- <i class="el-icon-upload"></i> -->
            <img src="@/assets/images/upload.png" alt="" />
            <div class="el-upload__text">
              将文件拖到此处，或<em>点击上传</em>
            </div>
            <div class="el-upload__tip" slot="tip">
              <span>注：请参照模版，批量导入车辆</span
              ><span class="downTemplate" @click="getImp">下载模版</span>
            </div>
          </el-upload>
          <div class="download">
            <el-button
              type="button"
              class="centerCzbtn"
              @click="upload"
              :loading="uploadLoading"
              >上传</el-button
            >
          </div>
        </div>
      </div>
    </el-drawer>

    <!-- 删除弹框 -->
    <el-dialog title="删除" :visible.sync="delDia" width="30%">
      <span
        >您当前正在执行删除车辆【{{ rowData.carNumber }}】操作,确认继续?</span
      >
      <span slot="footer" class="dialog-footer">
        <button class="centerCzbtn mr20" @click="confirmDel">确 定</button>
        <button class="centerBtn resetbtn" @click="delDia = false">
          取 消
        </button>
      </span>
    </el-dialog>

    <!-- 收回弹框 -->
    <el-dialog title="收回" :visible.sync="isTakeBack" width="30%">
      <span>确认要收回吗?</span>
      <span slot="footer" class="dialog-footer">
        <button class="centerCzbtn mr20" @click="confirmBack">确 定</button>
        <button class="centerBtn resetbtn" @click="isTakeBack = false">
          取 消
        </button>
      </span>
    </el-dialog>

    <!-- 分配企业 -->
    <el-drawer title="分配企业" :visible.sync="fpqyDraw" direction="rtl">
      <div class="demo-drawer__content">
        <div class="fenpeiSz">
          当前车辆所属企业：<span
            v-if="rowData.companyId == '000000' || rowData.companyId == null"
          >
            未分配</span
          >
          <span v-else>{{ rowData.companyName }}</span>
        </div>
        <div>
          <el-radio-group v-model="companyId">
            <el-radio
              v-show="item.companyName"
              class="radioFp"
              :props="{ value: 'id', label: 'companyName' }"
              :label="item.id"
              v-for="(item, index) in enterpriseList"
              :key="item.id"
            >
              {{ item.companyName }}</el-radio
            >
          </el-radio-group>
        </div>
      </div>

      <div class="demo-drawer__footer">
        <button class="centerCzbtn mr20" @click="configDistribution">
          确认分配
        </button>
        <button class="centerBtn resetbtn" @click="fpqyDraw = false">
          取消
        </button>
      </div>
    </el-drawer>

    <!-- 车辆注册详情 -->
    <el-drawer
      title="车辆注册详情"
      :visible.sync="vehRegDetail"
      direction="rtl"
      class="vehDetail"
    >
      <div><span>车辆编号：</span>{{ this.rowData.carNumber }}</div>
      <div><span>车辆VIN：</span>{{ this.rowData.carVin }}</div>
      <div><span>车辆类型：</span>{{ this.rowData.carTypeName }}</div>
      <div><span>车辆子类型：</span>{{ this.rowData.carSonTypeName }}</div>
      <div><span>运营类别：</span>{{ this.rowData.operationTypeName }}</div>
      <div><span>车辆牌证：</span>{{ this.rowData.carLicense }}</div>
      <div><span>OBU编号：</span>{{ this.rowData.obuNo }}</div>
      <div><span>可乘坐人数：</span>{{ this.rowData.seatNumber }}</div>
      <div>
        <span>所属企业：</span
        >{{ this.rowData.companyName ? this.rowData.companyName : "未分配" }}
      </div>
    </el-drawer>
  </div>
</template>

<script>
import Table from "../../components/Table.vue";
import {
  _FindAllPage,
  _DeleteCar,
  _CheckCarNumber,
  _AddCarRegister,
  _UpdateCarRegister,
  _CheckUpdateCarNumber,
  _CheckUpdateCarObuNo,
  _UpdateCarCompany,
  _CheckCarObuNo,
  _RecoveryCarCompany,
  _CarExport,
  _CarImp,
  _ExcelUploadCar,
} from "../../api/vehRegistration";
import {
  _CarTypeAll,
  _FindAllCarOperationType,
  _FindAllCompany,
} from "../../api/selCar";

export default {
  components: {
    Table,
  },
  data() {
    var valCarNumber = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请输入车辆编号"));
      } else {
        if (this.isAdd) {
          _CheckCarNumber({
            carNumber: this.form.carNumber,
          }).then((res) => {
            if (res.success) {
              callback();
            } else {
              return callback(new Error(res.message));
            }
          });
        } else {
          _CheckUpdateCarNumber({
            carNumber: this.form.carNumber,
            carRegisterId: this.rowData.id,
          }).then((res) => {
            if (res.success) {
              callback();
            } else {
              return callback(new Error(res.message));
            }
          });
        }
      }
    };

    var valCarObu = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请输入OBU编号"));
      } else {
        if (this.isAdd) {
          _CheckCarObuNo({
            obuNo: this.form.obuNo,
          }).then((res) => {
            if (res.success) {
              callback();
            } else {
              return callback(new Error(res.message));
            }
          });
        } else {
          _CheckUpdateCarObuNo({
            obuNo: this.form.obuNo,
            carRegisterId: this.rowData.id,
          }).then((res) => {
            if (res.success) {
              callback();
            } else {
              return callback(new Error(res.message));
            }
          });
        }
      }
    };

    var checkSeatNumber = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请输入车辆座位数"));
      } else {
        if (isNaN(value)) {
          return callback(new Error("请输入数字"));
        } else {
          // if (value > 6) {
          //   return callback(new Error("车辆信息有误"));
          // } else {
          //   callback();
          // }
          callback();
        }
      }
    };
    var checkPassNumber = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请输入可坐人数"));
      } else {
        if (isNaN(value)) {
          return callback(new Error("请输入数字"));
        } else {
          callback();
        }
      }
    };
    return {
      loading: false,
      delDia: false, //删除弹框显示
      isTakeBack: false,
      fileList: [],
      exportLoad: false, //车辆导出
      uploadLoading: false,
      total: 0,
      fpqyDraw: false,
      isAdd: true,
      vehRegDetail: false,
      leaveShow: true, //控制车辆座位数和可坐人数显示
      AddLoading: false,
      companyId: "",
      rowData: {}, //行数据
      radioList: [
        {
          value: 1,
          label: "武汉东风企业XX1",
        },
        {
          value: 2,
          label: "武汉东风企业XX2",
        },
        {
          value: 3,
          label: "武汉东风企业XX3",
        },
        {
          value: 4,
          label: "武汉东风企业XX4",
        },
        {
          value: 5,
          label: "武汉东风企业XX5",
        },
        {
          value: 6,
          label: "武汉东风企业XX6",
        },
        {
          value: 7,
          label: "武汉东风企业XX7",
        },
      ],
      carTypeList: [],
			carTypeOption: [],
			carTypeOptionParams: {
				children: "children",
				label: "typeName",
				value: "id",
				checkStrictly: true,
			},
			selectCarType: '',
      searchObj: {
        carNumber: null,
        carVin: null,
        companyId: null,
				carTypeId: null,
        page: 1,
        size: 10,
      },
      carSubType: [], //车辆子类型

      drawer: false,
      ImportVehicle: false,
      enterpriseList: [],
      tableData: [],
      columns: [
        {
          label: "车辆编号",
          // prop: "carNumber",
          render: (h, params) => {
            return h(
              "div",
              {
                style: {
                  color: "#1683D9",
                  cursor: "pointer",
                },
                on: {
                  click: () => {
                    this.rowData = params.row;
                    this.vehRegDetail = true;
                  },
                },
              },
              params.row.carNumber
            );
          },
        },
        {
          label: "车辆VIN",
          prop: "carVin",
        },
        {
          label: "车辆类型",
          prop: "carTypeName",
        },
        {
          label: "车辆子类型",
          prop: "carSonTypeName",
        },
        {
          label: "运营类别",
          prop: "operationTypeName",
        },
        {
          label: "车辆牌证",
          prop: "carLicense",
        },
        {
          label: "OBU编号",
          prop: "obuNo",
        },
        {
          label: "注册时间",
          prop: "createTime",
        },
        {
          label: "当前所属企业",
          render: (h, params) => {
            if (
              params.row.companyId == "000000" ||
              params.row.companyId == null
            ) {
              return h("span", "未分配");
            } else {
              return h("span", params.row.companyName);
            }
          },
        },
        {
          label: "操作",
          // fixed: "right",
          width: 200,
          render: (h, params) => {
            return h("div", {}, [
              h(
                "span",
                {
                  class: "operationA ",
                  on: {
                    click: () => {
                      this.rowData = params.row;
                      this.isAdd = false;
                      this.drawer = true;
                      let filterArr = this.operType.filter(
                        (e) => e.id == params.row.operationTypeId
                      );
                      if (filterArr.length > 0) {
                        if (filterArr[0].level == 1) {
                          this.leaveShow = true;
                          this.rules.seatNumber[0].required = true;
                          this.rules.passengerNumber[0].required = true;
                        } else {
                          this.leaveShow = false;
                          this.rules.seatNumber[0].required = false;
                          this.rules.passengerNumber[0].required = false;
                          this.form.passengerNumber = null;
                          this.form.seatNumber = null;
                        }
                      }
                      this.form.carTypeId = [];
                      this.form.carTypeId = params.row.carTypeId;
                      (this.form.carNumber = params.row.carNumber),
                        (this.form.carVin = params.row.carVin),
                        (this.form.carLicense = params.row.carLicense),
                        (this.form.obuNo = params.row.obuNo),
                        (this.form.operationTypeId =
                          params.row.operationTypeId),
                        (this.form.seatNumber = params.row.seatNumber),
                        (this.form.passengerNumber =
                          params.row.passengerNumber);
                    },
                  },
                },
                "编辑"
              ),
              h(
                "span",
                {
                  class: "operationA ml20",
                  on: {
                    click: () => {
                      this.rowData = params.row;
                      this.delDia = true;
                    },
                  },
                },
                "删除"
              ),
              h(
                "span",
                {
                  class: "operationA tx20",
                  on: {
                    click: () => {
                      this.companyId = params.row.companyId;
                      this.rowData = params.row;

                      if (
                        params.row.companyId == "000000" ||
                        params.row.companyId == null
                      ) {
                        this.fpqyDraw = true;
                      } else {
                        this.isTakeBack = true;
                      }
                    },
                  },
                },
                params.row.companyId == "000000" || params.row.companyId == null
                  ? "分配企业"
                  : "收回"
              ),
            ]);
          },
        },
      ],

      operType: [],
      form: {
        carNumber: "",
        carVin: "",
        carLicense: "",
        obuNo: "",
        carTypeId: [],
        operationTypeId: "",
        seatNumber: "",
        passengerNumber: "",
      },
      rules: {
        carNumber: [
          {
            validator: valCarNumber,
            required: true,
            // message: '请输入车辆编号',
            trigger: "blur",
          },
        ],
        carVin: [
          {
            required: true,
            message: "请输入车辆VIN",
            trigger: "blur",
          },
        ],
        obuNo: [
          {
            validator: valCarObu,
            required: true,
            // message: '请输入OBU编号',
            trigger: "blur",
          },
        ],
        carTypeId: [
          {
            // validator: validateCa,
            type: "string",
            required: true,
            message: "请选择车辆子类型",
            trigger: "change",
          },
        ],
        operationTypeId: [
          {
            required: true,
            message: "请选择运营类别",
            trigger: "change",
          },
        ],
        seatNumber: [
          {
            validator: checkSeatNumber,
            required: true,
            trigger: "blur",
          },
        ],
        passengerNumber: [
          {
            validator: checkPassNumber,
            required: true,
            trigger: "blur",
          },
        ],
      },
      formLabelWidth: "100px",
      timer: null,
      type: null,
    };
  },
  mounted() {
		this.getCarTypeOption();
    this.findAllCompany();
    this.getTableData();
    this.queryCarType();
    this.queryOperationType();
  },
  methods: {
		//获取车辆类型
		async getCarTypeOption() {
			_CarTypeAll().then((res) => {
				if (res.success == true) {
					this.carTypeOption = res.data.list;
				}
			});
		},
	
		handleCarTypeOptionChange(value){
			this.selectCarType = value;
			
			if (value.length == 1) {
				this.searchObj.carTypeId = value[0];
			} else {
				this.searchObj.carTypeId = value[1];
			}
		},
		
    // 下载模板
    downTemplate() {
      this.searchObj.page = 1;
      this.searchObj.size = 1;
      this.type = 1;
      this.exportCar();
    },
    exportC() {
      this.exportLoad = true;
      this.type = 2;
      this.exportCar();
    },
    //导出车辆
    exportCar() {
      _CarExport(this.searchObj).then((response) => {
        const url = window.URL.createObjectURL(
          new Blob([response], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          })
        );
        const link = document.createElement("a");
        link.style.display = "none";
        link.href = url;
        this.exportLoad = false;

        link.download = "车辆注册导出数据.xlsx";

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        this.timer = setTimeout(() => {
          this.$message.success("导出成功");
        }, 3000);
      });
    },
    async getImp() {
      _CarImp(this.searchObj).then((response) => {
        const url = window.URL.createObjectURL(
          new Blob([response], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          })
        );
        const link = document.createElement("a");
        link.style.display = "none";
        link.href = url;
        this.exportLoad = false;

        link.download = "车辆注册导入模板.xlsx";

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        this.timer = setTimeout(() => {
          this.$message.success("下载成功");
        }, 3000);
      });
    },
    handleUpXls(file, fileList) {
      console.log(fileList);
      this.fileList = fileList;
    },
    //上传
    async upload() {
      let formData = new FormData();
      formData.append("file", this.fileList[0].raw);
      _ExcelUploadCar(formData).then((res) => {
        this.uploadLoading = true;
        console.log(res);
        // if (!res) {
        //   console.log("aa");
        //   this.fileList = [];
        // }
        if (res.success) {
          this.$message.success("导入成功");
          this.fileList = [];
          this.ImportVehicle = false;
          this.getTableData();
        } else {
          this.$message.error(res.message);
          this.fileList = [];
        }
        this.uploadLoading = false;
      });
    },

    //确认分配企业
    async configDistribution() {
      if (this.companyId) {
        let { success, message, data } = await _UpdateCarCompany({
          carRegisterId: this.rowData.id,
          companyId: this.companyId,
        });
        if (success) {
          this.$message.success("设置成功");
          this.fpqyDraw = false;

          this.getTableData();
          // this.reset();
        } else {
          this.$message.success(message);
        }
      } else {
        this.$message.error("请选择企业");
      }
    },

    // 收回车辆
    async confirmBack() {
      // this.takeBack('111111')
      let { success, message, data } = await _RecoveryCarCompany({
        carRegisterId: this.rowData.id,
        companyId: "000000",
      });
      if (success) {
        this.$message.success("收回成功");
        this.isTakeBack = false;

        this.getTableData();
      } else {
        this.$message.error(message);
      }
    },
    //重置
    reset() {
      this.searchObj = {
        carNumber: null,
        carVin: null,
        companyId: null,
        page: 1,
        size: 10,
      };
      this.getTableData();
    },
    // 查询表格数据
    async getTableData(pagination) {
      if (pagination) {
        if (pagination.page) {
          this.searchObj.page = pagination.page;
          this.searchObj.size = pagination.limit;
        }
      }

      this.loading = true;
      let { success, message, data } = await _FindAllPage(this.searchObj);
      if (success == true) {
        this.tableData = data.list;
        this.total = data.total;
      } else {
        this.$message.error(message);
      }
      this.loading = false;
    },
    //操作
    operation() {
      if (
        parseInt(this.form.passengerNumber) > parseInt(this.form.seatNumber)
      ) {
        this.$message.error("可坐人数不可超过实际座位数");
      } else {
        if (this.isAdd) {
          this.addCar();
        } else {
          this.updateCar();
        }
      }
    },

    //关闭弹框
    drawClose() {
      // this.$refs['form'].resetFields()
    },
    //改变运营类别
    changeOperType(val) {
      let filterArr = this.operType.filter((e) => e.id == val);
      console.log(filterArr);
      if (filterArr.length > 0) {
        console.log(filterArr[0].level);
        if (filterArr[0].level == 1) {
          this.leaveShow = true;
          this.rules.seatNumber[0].required = true;
          this.rules.passengerNumber[0].required = true;
        } else {
          this.leaveShow = false;

          this.rules.seatNumber[0].required = false;
          this.rules.passengerNumber[0].required = false;
          this.form.passengerNumber = null;
          this.form.seatNumber = null;
        }
      }
    },
    //显示添加车辆弹框
    addCarDia() {
      (this.form = {
        carNumber: "",
        carVin: "",
        carLicense: "",
        obuNo: "",
        carTypeId: "",
        operationTypeId: "",
        seatNumber: "",
        passengerNumber: "",
      }),
        (this.leaveShow = true);
      this.drawer = true;
      this.isAdd = true;
    },
    //修改车辆
    updateCar() {
      this.$refs.form.validate((valid) => {
        console.log(this.form.carTypeId);
        if (valid) {
          // _CheckUpdateCarNumber({
          //   carNumber: this.form.carNumber,
          //   carRegisterId: this.rowData.id
          // }).then(res => {
          //   if (res.success) {
          //     _CheckUpdateCarObuNo({
          //       obuNo: this.form.obuNo,
          //       carRegisterId: this.rowData.id
          //     }).then(res => {
          //       if (res.success) {
          //         let newObj = JSON.parse(JSON.stringify(this.form))
          //         newObj.id = this.rowData.id
          //         _UpdateCarRegister(newObj).then(res => {
          //           if (res.success) {
          //             this.$message.success('修改成功')
          //             this.drawer = false
          //             this.reset()
          //           } else {
          //             this.$message.error(res.message)
          //           }
          //         })
          //       } else {
          //         this.$message.error(res.message)
          //       }
          //     })
          //   } else {
          //     this.$message.error(res.message)
          //   }
          // })
          let newObj = JSON.parse(JSON.stringify(this.form));
          newObj.id = this.rowData.id;
          this.AddLoading = true;
          _UpdateCarRegister(newObj).then((res) => {
            if (res.success) {
              this.$message.success("修改成功");
              this.AddLoading = false;
              this.drawer = false;
              this.reset();
            } else {
              this.AddLoading = false;
              this.$message.error(res.message);
            }
          });
        }
      });
    },
    //添加车辆
    addCar() {
      console.log("点击");
      this.$refs.form.validate((valid) => {
        console.log(valid);
        console.log(this.rules);

        if (valid) {
          //  _CheckCarObuNo({
          //         obuNo: this.form.obuNo
          //       }).then(res => {
          //         if (res.success) {
          this.AddLoading = true;
          _AddCarRegister(this.form).then((res) => {
            if (res.success) {
              this.$message.success("添加成功");
              this.drawer = false;
              this.AddLoading = false;
              this.reset();
            } else {
              this.$message.error(res.message);
              this.AddLoading = false;
            }
          });
          //   } else {
          //     this.$message.error(res.message)

          //   }
          // })
        } else {
          // _AddCarRegister
          return false;
        }
      });
    },
    //点击搜索
    search() {
      let searchPage = {
        limit: 10,
        page: 1
      }
      this.getTableData(searchPage);
    },
    //确认删除车辆
    confirmDel() {
      this.delCar(this.rowData.id);
    },
    // 删除车辆
    async delCar(carRegisterId) {
      let { success, message, data } = await _DeleteCar({
        carRegisterId,
      });
      if (success) {
        this.$message.success("删除成功");
        this.delDia = false;
        this.reset();
      } else {
        this.$message.error(message);
      }
    },

    //查询全部车辆类型
    async queryCarType() {
      let { success, data, message } = await _CarTypeAll();
      if (success) {
        // this.carTypeList = data.list
        this.carSubType = data.list;
        this.carSubType.forEach((e, i) => {
          this.carSubType[i].disabled = true;
        });
      }
    },

    //查询全部的车辆运营类型
    async queryOperationType() {
      let { success, data, message } = await _FindAllCarOperationType();
      if (success) {
        this.operType = data.list;
      }
    },

    //查询所有企业
    async findAllCompany() {
      let { success, data, message } = await _FindAllCompany();
      if (success) {
        this.enterpriseList = data.list;
      }
    },
  },
  computed: {},
};
</script>

<style scoped lang="less">
.fenpeiSz {
  color: #666666;
  font-size: 16px;
}

.fenpeiSz > span {
  color: #1683d9;
  font-weight: bold;
}

.el-radio-group {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.el-radio-group > label {
  width: 50%;
}

.el-radio {
  margin-right: 0px;
}

.radioFp {
  margin-top: 40px;
}

.vehDetail {
  font-size: 14px;
  color: #999;

  div {
    margin-top: 36px;

    span {
      color: #333;
      margin-left: 10px;
      width: 100px;
      text-align: right;
      display: inline-block;
    }
  }
}

.importDv {
  width: 80%;
  height: 180px;
  margin: 0px auto;

  .download {
    display: flex;
    margin-top: 40px;
    justify-content: center;
  }

  .upload-demo {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    /deep/ .el-upload-dragger {
      background-color: #f5f8fa !important;
      border: 0px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }

    .el-upload__tip {
      width: 100%;
      display: flex;
      justify-content: space-between;

      .downTemplate {
        color: #1683d9;
        cursor: pointer;
      }
    }
  }
}
</style>
